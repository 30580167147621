import React from 'react';
import Reveal from 'react-reveal/Reveal';

const Paymentprocess = () => {
    return(
        <section className="payment_clients_area">
            <div className="clients_bg_shape_top"></div>
            <div className="clients_bg_shape_right"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <Reveal effect="fadeInLeft" duration={500}>
                            <div className="payment_features_content pr_70">
                                <div className="icon">
                                    <img className="img_shape" src={require('../img/home9/icon_shape.png')} alt=""/>
                                    <img className="icon_img" src={require('../img/home9/icon2.png')}  alt=""/>
                                </div>
                                <h2>Cleaner world</h2>
                                <p>Let’s make our planet cleaner.</p>
                                <a href=".#" className="btn_hover agency_banner_btn pay_btn">Sign Up for Free</a>
                                <a href=".#" className="btn_hover agency_banner_btn pay_btn pay_btn_two">Sign Up for Free</a>
                            </div>
                        </Reveal>                
                    </div>
                    <div className="col-lg-6  d-flex align-self-center justify-content-end">
                        <div className="payment_clients_inner">
                        <div className="testimonial_img">
                                <img className='img img-fluid' src={require ('../img/home9/testimonial_img.png')} alt="" width="500px"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Paymentprocess;