import React from 'react';

const ServiceDetails =()=> {
    return(
        <section className="service_details_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 pr_70">
                        <div className="job_info">
                            <div className="info_head">
                                <i className="ti-receipt"></i>
                                <h6 className="f_p f_600 f_size_18 t_color3">BTC Green</h6>
                            </div>
                            <div className="info_item">
                                <h6>Cleaner</h6>
                            </div>
                            <div className="info_item">
                                <h6>Greener</h6>
                            </div>
                            <div className="info_item">
                                <h6>Quality</h6>
                            </div>
                            <div className="info_item">
                                <h6>Eco-Friendly</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="details_content">
                            <div className="sec_title">
                                <p className="f_400 f_size_15">Now 76% of bitcoin miners are using renewable energy.</p>
                                <p className="f_400 f_size_15">Using Perpetual's expertise and knowledge of environmentally friendly technology we are developing low-cost, environmentally responsible energy solutions for powering large-scale blockchain mining operations.</p>
                                <p className="f_400 f_size_15">Perpetual Industries is strategically focused on cryptocurrency mining with the introduction of a proprietary “Green Energy Mining” (GEM) System.</p>
                                <p className="f_400 f_size_15">Powered with renewable & surplus energy sources such as wind, solar, natural gas, wind, and geothermal that utilize battery storage technology.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ServiceDetails;